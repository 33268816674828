
(function(template){
  define("vue!ESSearchComponents/SearchInput.vue", ['Vue'], function (Vue) {
    Vue.component('search-input', {
      template: template,

      props: ['value', 'placeholder', 'triggerLimit'],

      methods: {
        clear() {
          this.$emit('clear');
          this.$refs.input.value = '';
          this.$refs.input.focus();
        },

        search(query) {
          if (query.length < this.$props.triggerLimit) {
            Vue.nextTick(() => this.$emit('clear', query));
            return;
          }

          if (this.timeout) {
            clearTimeout(this.timeout);
          }

          const emitAction = !!query ? 'search' : 'clear';
          this.timeout = setTimeout(() => this.$emit(emitAction, query), 200);
        },
      }
    });
  });
})('' + 
' <div>' + 
' <input class="essearch__field" type="text" @input="search($event.target.value)" :placeholder="placeholder" ref="input">' + 
' <div class="essearch__clear" :class="{\'essearch__clear--show\': value.length > 0 }" @click="clear()"></div>' + 
'' + 
' <div class="essearch__button essearch__button--mobile">{{ placeholder }}</div>' + 
' </div>' + 
'' + '');
