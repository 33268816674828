define('Focus_Sizefinder/js/model/data-loader',[
    'jquery',
    'mage/storage'
],
function ($, storage) {
    'use strict';


    var URI_ENDPOINT = '/rest/V1/focus/size-finder/:sku';


    return function (sku) {
        var url = URI_ENDPOINT.replace(':sku', sku);
        return storage.get(url);
    };



});

