/**
 * @package Ponbike_Compare
 */
define('Ponbike_Compare/js/replaceItem',[
    'jquery'
], function ($) {
    'use strict';

    $.widget('mage.compareBar', {

        options: {
            selectbox: false
        },

        elements: {
            replaceItem: '.compare-bar__replace-item',
            compareBar: '.compare-bar',
            compareInner: '.compare-bar__inner',
            emptyCompare: '.empty-compare',
            deleteItem: '.compare-modal .action.delete',
            compareSlider: '.compare-slider',
            replaceContainer: '.compare-replace',
            closeButton: '.compare-bar__close, .link--close',
        },

        /**
         * Initialize widget
         */
        _create: function () {
            var self = this;
            $(document).on(
                'click', self.elements.replaceItem, function () {
                    self._replaceCompareItem(this);
                }
            );

            self._replaceCompareItem(this);

            self._bindCloseButton();

            $(function () {
                $('.compare input').prop('disabled', false);
            });

            $(document).on(
                'click', self.elements.deleteItem, function (a) {
                    var target = a.target;
                    var productId = target.attributes["data-id"].nodeValue;
                    var store = target.attributes["data-store"].nodeValue;

                    $.ajax({
                        url: '/' + store + '/catalog/product_compare/ajax',
                        data: {
                            'product': productId
                        },
                        success: function (returnValue) {
                            self._updateCompareBar(returnValue);
                            //Disable checked on category if applicable
                            var item = $("input#compare_" + productId + ".compare.icon-check-mark-before");
                            if (item) {
                                item.prop('checked', false);
                            }
                        },
                        error: function (returnValue) {
                        }
                    });
                }
            )
        },

        _bindCloseButton: function () {
            var self = this;
            $(document).on(
              'click', self.elements.closeButton, function (ev) {
                  ev.preventDefault();

                  self._setCompareBarVisibility(false);
              }
            );
        },

        _replaceCompareItem: function(element) {
            var self = this,
                newProductId = $('input[name="new_product"]').val(),
                oldProductId = $(element).data('id'),
                storeId = $('.compare-bar__inner').data("store") || $(element).data("store");

            $(this.elements.compareBar)
                .addClass('loading');
            $(this.element)
                .closest('.compare-replace')
                .addClass('loading');
            $.ajax({
                url: '/' + storeId + '/catalog/product_compare/replace',
                data: {
                    'newProductId': newProductId,
                    'oldProductId': oldProductId
                },
                success: function (returnValue) {
                    this._updateCompareBar(returnValue);
                    $('body').trigger('contentUpdated');
                }.bind(this),
                always: function (returnValue) {
                    Object.assign(updateObject, returnValue);
                    $(this.element)
                        .closest('li.product-item')
                        .removeClass('compare-active');
                }.bind(this)
            });

        },

        _updateCompareBar: function (cb) {
            const bannerHTML = cb.bannerHtml;
            const visible = cb.noItems === false

            $(this.elements.compareBar).html(bannerHTML);

            this._setCompareBarVisibility(visible);
            this._reload();
            this._finalize(cb);
        },

        _finalize: function(cb) {
            $('.compare input[data-id="'+cb.oldProductId+'"]')
                .prop('checked', false);

            $(this.elements.compareBar)
                .removeClass('loading');
            $(this.element)
                .closest('.compare-replace')
                .removeClass('loading');

            $('body, html')
                .removeClass('overlay');
            $('.compare-replace')
                .removeClass('active');
            $('li.product-item')
                .removeClass('compare-active');
            $(this.elements.compareBar)
                .removeClass('replace');
        },

        _setCompareBarVisibility: function (visible) {
            if (visible === true) {
                $(this.elements.compareBar).addClass("active");
                $('body').addClass('compare_bar_is-open');
                $(this.elements.compareBar).addClass('is-open');
            } else {
                $('body').removeClass('compare_bar_is-open');
                $(this.elements.compareBar).removeClass('is-open');
                $('.compare-modal').removeClass('active');
            }
        },

        _reload: function () {
            $(this.element).prop('disabled', false);
            $(this.elements.compareBar).removeClass('loading');
        }

    });

    return $.mage.compareBar;
});

