define('Focus_Sizefinder/js/sizefinder',[
        'jquery',
        'ko',
        'underscore',
        'uiComponent',
        'Magento_Ui/js/modal/modal',
        'Focus_Sizefinder/js/model/data-loader'
    ],
    function ($, ko, _, Component, modal, dataLoader) {
    'use strict';

        return Component.extend({
            defaults: {
                step1Active: true,
                step2Active: false,
                step3Active: false,
                unit: 'metric',
                linkQuery: '.framesize-link',
                metricActive: true,
                imperialActive: false,
                bodyLengthOptions: [],
                bodyLength: '',
                recommendedFrameSize: '',
                recommendedFrameSizeName: '',
                errorMessage: '',
                modalOpened: false
            },

            initObservable: function () {
                this._super();
                this.observe([
                    'step1Active',
                    'step2Active',
                    'step3Active',
                    'unit',
                    'bodyLengthOptions',
                    'bodyLength',
                    'sizeData',
                    'modalOpened',
                    'errorMessage'
                ]);

                this.isImperialMode = ko.computed(function() {
                    return this.unit() === 'imperial';
                }, this);

                // Size options available in given unit
                this.sizeOptions = ko.computed(function() {
                    var labelKey = this.isImperialMode()
                        ? 'size_ft'
                        : 'size_cm';

                    var unitSuffix = this.isImperialMode()
                        ? ' ft'
                        : ' cm';

                    return _.map(this.bodyLengthOptions(), function(option) {
                        return {
                            value: option.id,
                            label: option[labelKey] + unitSuffix
                        };
                    });
                }, this);

                // Observer for providing selected size recommendation
                this.currentBodyLength = ko.computed(function() {
                    var options = this.bodyLengthOptions();
                    var selected = this.bodyLength();
                    var i = options.length;
                    if (selected.length) {
                        while (--i >= 0) {
                            if (options[i].id === selected[0]) {
                                this.errorMessage('');
                                return options[i];
                            }
                        }
                    }

                    this.bodyLength('');
                    this.errorMessage(this.initialErrorMessage);
                    return null;
                }, this);

                this.recommendedFrameSize = ko.computed(function() {
                    return this.currentBodyLength() && this.currentBodyLength().size_rec;
                }, this);

                return this;
            },

            initialize: function (config) {
                var scope = this;
                this._super(config);

                $(function() {
                    scope.initSizefinder();
                });

                scope.bodyLengthOptions.subscribe(function(data) {
                    $(scope.linkQuery).toggle(!!(data && data.length));
                });

                // Load available size recommendations for the provided sku
                dataLoader(config.sku).done(function (data) {
                    scope.bodyLengthOptions(data);
                });
            },

            initSizefinder: function () {
                var scope = this;
                $(this.linkQuery).hide().click(function(e) {
                    e.preventDefault();

                    if(!scope.modalOpened()){
                        //initialize modal on first click
                        $('#componentSizefinder').modal({
                            type: 'popup',
                            modalClass: 'sizefinder-modal',
                            buttons: [],
                            clickableOverlay: false,
                            autoOpen: true,
                            responsive: false,
                            parentModalClass: '_has-modal-custom',
                            overlayClass: 'modal-custom-overlay'
                        });
                        scope.modalOpened(true);

                        //hide modal on background-click
                        $(".modal-custom-overlay" ).click(function() {
                            scope.closeSizefinder();
                        });
                    } else{
                        //show modal again if previously opened
                        $('.sizefinder-modal').addClass('_show');
                        $('.modal-custom-overlay').show();
                        $('body').addClass('_has-modal-custom')

                        // re-validate - options may have changed
                        if (scope.step3Active()) {
                            scope.goToStep3();
                        }
                    }
                });
            },

            closeSizefinder: function () {
                $('.sizefinder-modal').removeClass('_show');
                $('.modal-custom-overlay').hide();
                $('body').removeClass('_has-modal-custom');

                this.reset();
            },

            goToStep1: function () {
                this.step1Active(true);
                this.step2Active(false);
                this.step3Active(false);
            },

            goToStep2: function () {
                this.step1Active(false);
                this.step2Active(true);
                this.step3Active(false);
            },

            goToStep3: function () {
                this.errorMessage('');

                if (!this.validateSizeOption(this.recommendedFrameSize())) {
                    this.errorMessage(this.noRecommendationErrorMessage);
                }

                if (!this.currentBodyLength()) {
                    this.errorMessage(this.initialErrorMessage);
                }
                this.step1Active(false);
                this.step2Active(false);
                this.step3Active(true);
            },

            /**
             * Reset size-finder to its original state
             */
            reset: function() {
                this.goToStep1();
                this.errorMessage('');
                this.bodyLength('');

                // Work around for ko-bug that does not reset empty values
                $('.control-select select', '#componentSizefinder').val('');
            },

            /**
             * Select size and close
             *
             * @return {void}
             */
            selectSizeAndClose: function () {
                var size = this.recommendedFrameSize();
                if(size){
                    this.selectSizeOption(size);
                }
                this.closeSizefinder();
            },

            /**
             * Select product size option and return true or false
             * if available or not available respectively.
             *
             * We have to
             *
             * @param {string} size
             *
             * @returns {boolean}
             */
            selectSizeOption: function(size)
            {
                $(this.size_option_element).find('option').filter(function() {
                    return this.disabled !== true && this.innerText === size;
                }).prop('selected', true).end().trigger('change');
            },

            /**
             * Check if size option is available
             *
             * @param {string} size
             *
             * @returns {boolean}
             */
            validateSizeOption: function(size)
            {
                if (!$(this.size_option_element).length) {
                    return !!size;
                }

                return !!$(this.size_option_element).find('option').filter(function() {
                    return this.disabled !== true && this.innerText === size;
                }).length;
            }
        });
    }
);

