define('js/gallery-js',[
    "jquery",
    "jquery-zoom"
],
function ($) {
    "use strict";

    return function (config, el) {
        var nextButton = $('[data-button="nextimage"]'),
            prevButton = $('[data-button="previmage"]'),
            imagesCount = 0,
            imageHolder = $('.imageholder'),
            imageHolderImg = $('.imageholder img'),
            zoomBox = $('.gallery-zoom-box'),
            fullscreenIcon = $('.gallery-fullscreen'),
            zoomIcon = $('.gallery-image-zoom'),
            exitFullscreen = $('.gallery-fullscreen-close');

        // Zoom gallery fullscreen
        fullscreenIcon.on('click', function () {
            enterFullscreen(zoomBox.get(0));
            zoomBox.addClass('fullscreen');
        });

        // Exit fullscreen
        exitFullscreen.on('click', function () {
            exitFullscreen();
            zoomBox.removeClass('fullscreen');
            imageHolder.removeClass('zoom');
            imageHolderImg.trigger('zoom.destroy');
        });

        // Exit fullscreen by pressing escape key
        zoomBox.bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
            var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen,
                event = state ? 'enterFullscreen' : 'exitFullscreen';

            if (event == 'exitFullscreen') {
                zoomBox.removeClass('fullscreen');
                imageHolder.removeClass('zoom');
                imageHolderImg.trigger('zoom.destroy');
            }
        });

        // Activate zooming functionality
        zoomIcon.on('click', function () {
            var zoomItem = $(this).parent(),
                zoomImg = $('.zoomImg', zoomItem) ? $('.zoomImg', zoomItem) : '';

            if (zoomItem.hasClass('zoom')) {
                zoomItem.removeClass('zoom');
                zoomItem.trigger('zoom.destroy');
            } else {
                zoomItem.addClass('zoom');
                zoomItem.zoom({
                    magnify: 1,
                    url: zoomItem.data('image-big')
                });
                setTimeout(function () {
                    zoomImg = $('.zoomImg', zoomItem);
                    zoomImg.mousemove(function () {
                        zoomImg.addClass('zoomed');
                    });
                }, 250);
            }
        });

        // Enter fullscreen mode
        var enterFullscreen = function (e) {
            if (e.requestFullscreen) {
                e.requestFullscreen();
            } else if (e.msRequestFullscreen) {
                e.msRequestFullscreen();
            } else if (e.mozRequestFullScreen) {
                e.mozRequestFullScreen();
            } else if (e.webkitRequestFullscreen) {
                e.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        };

        // Exit fullscreen mode
        var exitFullscreen = function () {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        };

        // Geometry size changer
        $('.product-geometry .size-changer').change(function () {
            $('.product-geometry').find('.size').hide();
            var size = $(this).val();
            $('.product-geometry .size.' + size).show();
            $('.product-geometry .size.' + size).next().show();
            if ($('.product-geometry .size.' + size).next().length > 0) {
                $('.product-geometry .bigger-size').html($('.product-geometry .size-changer option:selected').next().text());
            } else {
                $('.product-geometry .bigger-size').html('');
            }

        });

        // Make first image holder active and visible
        imageHolder.first().addClass('active');
        imageHolder.first().css({ 'display': 'block' });

        // Count total images
        imagesCount = $('.imageholder').length;

        var slideRight = function () {
            imageHolder.removeClass('zoom');
            imageHolderImg.trigger('zoom.destroy');

            if (nextButton.hasClass('unactive')) {
                return;
            }
            var imageCount = $('.imageholder.active').data('image-count');
            imageCount++;
            changeImage(imageCount);
            return false;
        };

        var slideLeft = function () {
            imageHolder.removeClass('zoom');
            imageHolderImg.trigger('zoom.destroy');

            if (prevButton.hasClass('unactive')) {
                return;
            }
            var imageCount = $('.imageholder.active').data('image-count');
            imageCount--;
            changeImage(imageCount);
            return false;
        };

        nextButton.on('click', function (e) {
            e.preventDefault();
            slideRight();
        });

        prevButton.on('click', function (e) {
            e.preventDefault();
            slideLeft();
        });

        if (typeof zoomBox !== 'undefined' && zoomBox.length > 0) {
            // Add swipe functionality for carousel
            zoomBox.swiperight(function () {
                if (!imageHolder.hasClass('zoom')) {
                    slideLeft();
                }
            });

            zoomBox.swipeleft(function () {
                if (!imageHolder.hasClass('zoom')) {
                    slideRight();
                }
            });
        }

        if (imagesCount == 1 || imagesCount == 0) {
            $('.product-specification').remove('.focus-icon.icon-dot');
            nextButton.addClass('unactive');
            prevButton.addClass('unactive');
        }

        function changeImage(imageCount) {
            // Activate new image
            $('.imageholder').removeClass('active');
            setTimeout(function () {
                $('.imageholder').removeAttr('style')
            }, 250);
            setTimeout(function () {
                $('.imageholder[data-image-count="' + imageCount + '"]').css({ 'display': 'block' });
            }, 250);
            setTimeout(function () {
                $('.imageholder[data-image-count="' + imageCount + '"]').addClass('active');
            }, 300);

            // Activate matching indicator
            $('.focus-icon.icon-dot').removeClass('active');
            $('span.icon-dot[data-indicator="' + imageCount + '"]').addClass('active');

            // Hide buttons if number is low or higher
            if (imageCount == (imagesCount)) {
                nextButton.addClass('unactive');
                prevButton.removeClass('unactive');
            } else if (imageCount > 1 && imageCount < (imagesCount)) {
                nextButton.removeClass('unactive');
                prevButton.removeClass('unactive');
            } else if (imageCount == 1) {
                nextButton.removeClass('unactive');
                prevButton.addClass('unactive');
            }
        }
    }
});

