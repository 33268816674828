/**
 * R.A.T. Technology disclaimer implementation
 * 
 * As a wise man once said, don't hate the player - hate the game
 */

define('js/rat',[
    'jquery',
], function ($) {
    'use strict';
    
    $(document).ready(function () {
        if (!$('body').hasClass('gb')) {
            return;
        }

        if ($('html:contains("R.A.T.")') || $('html:contains(" RAT ")') || $('html:contains("RAPID AXLE TECHNOLOGY")')) {
            $('.rat-technology').text('* R.A.T. thru-axles are sold under licence in the UK (UK patent GB2414971B)');
        }
    })
});

