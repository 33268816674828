
(function(template){
    define("vue!FocusESSearchComponents/ResultsGroup.vue", ['Vue'], function (Vue) {
        Vue.component('search-results-group', {
            template: template,

            props: {
                type: {
                    type: String,
                    default: ''
                },
                data: {
                    type: Object,
                    default: () => {
                        return {}
                    }
                },
                renderLimit: {
                    type: Object,
                    default: () => {
                        return {}
                    }
                },
                bikefinder: {
                    type: String,
                    default: ''
                }
            },

            methods: {
                showBikeFinder() {
                    setTimeout(() => {window.location = BASE_URL + `bikes#page=1`}, 0 );
                },
                returnRenderLimit(type) {
                    return this.$props.renderLimit[type] ? this.$props.renderLimit[type] : 3;
                }
            },
        });
    });
})('' + 
' <span :class="`essearch__resultsGroup essearch__resultsGroup--${type}`">' + 
' <h2 :class="`essearch__resultsGroup-header`">{{ data.label }}' + 
' <small v-if="data.items.length > returnRenderLimit(type)" :class="`essearch__resultsGroup-counter`">{{returnRenderLimit(type)}}+</small>' + 
' <small v-else :class="`essearch__resultsGroup-counter`">{{ data.items.length }}</small>' + 
' </h2>' + 
'' + 
' <search-result v-for="(item, index) in data.items.slice(0, returnRenderLimit(type))" :key="index" :type="type" :data="item" />' + 
'' + 
' <div v-if="type === \'category\'" class="essearch__loadall">' + 
' <button class="essearch__loadall-button btn-focus" v-on:click="showBikeFinder()">{{bikefinder}}</button>' + 
' </div>' + 
'' + 
' </span>' + 
'' + '');
