define('js/header',[
    "jquery",
    "scrollHandler"
], function($, scrollHandler){
    "use strict";
    var headerFunction = function (config, el) {
        var headerEl = $(el);

        function centerHeight() {
            $('.centered').each(function () {
                $(this).parent().css('height', '');
                if ($(window).width() >= 992) {
                    var $height = $(this).parent().outerHeight();
                    $(this).parent().css('height', $height);
                }
            });
        }

        centerHeight();

        $('html').click(function (e) {
            if ($('body').hasClass('no-scrolling') && $(window).width() >= 1025) {
                $('header, ul.main-navigation, li.dropdown').removeClass('open');
                $('li.dropdown > .main-submenu').slideUp(250);
                $('body').removeClass('no-scrolling');
            }
        });

        headerEl.click(function(e) {
            e.stopPropagation();
            var $this = $(e.target).closest('a.main-menu');

            if ($(window).width() >= 1025) {

                if ($this.parent().hasClass('dropdown')) {
                    e.preventDefault();
                    $('header, ul.main-navigation, li.dropdown').removeClass('open');

                    if ($this.next('.main-submenu').is(':visible')) {
                        $this.next('.main-submenu').slideUp(250);
                        $('body').removeClass('no-scrolling');
                    } else {
                        $('li.dropdown > .main-submenu').slideUp(250);
                        $this.next('.main-submenu').slideDown(250);
                        $this.parent().addClass('open');
                        $('header, ul.main-navigation').addClass('open');
                        $('body').addClass('no-scrolling');
                        setTimeout(function(){
                            $('.essearch__field').trigger('focus');
                        }, 500);
                    }
                }
            } else {
                if ($(e.target).parent().hasClass('more-content')) {
                    $(e.target).parent().toggleClass('open');
                } else if ($(e.target).hasClass('more-content')) {
                    $(e.target).parent().toggleClass('open');
                } else if ($(e.target).hasClass('icon-search')) {
                    e.preventDefault();
                    $('ul.main-navigation, .hamburger-toggle').removeClass('open');
                    $('header').removeClass('second-open');

                    if ($('.search .main-submenu').hasClass('open')) {
                        $('header, .search .main-submenu, .search-toggle').removeClass('open');
                        $('body').removeClass('no-scrolling');
                    } else {
                        $('header, .search .main-submenu, .search-toggle').addClass('open');
                        $('body').addClass('no-scrolling');
                        $('.essearch__field').trigger('focus');
                    }
                } else if ($(e.target).hasClass('hamburger-toggle')) {
                    e.preventDefault();
                    $('li.dropdown, li.bike-category-header, .search .main-submenu, .search-toggle').removeClass('open');
                    $('header').removeClass('second-open');

                    if ($('ul.main-navigation').hasClass('open')) {
                        $('header, ul.main-navigation, .hamburger-toggle').removeClass('open');
                        $('body').removeClass('no-scrolling');
                    } else {
                        $('header, ul.main-navigation, .hamburger-toggle').addClass('open');
                        $('body').addClass('no-scrolling');
                    }
                } else if ($(e.target).hasClass('icon-arrow-left-thin')) {
                    $('li.dropdown, li.bike-category-header').removeClass('open');
                    $('header').removeClass('second-open');
                } else if ($(e.target).hasClass('opener')) {
                    e.preventDefault();
                    $(e.target).closest('.bike-category-header').toggleClass('open');
                } else if ($this.parent().hasClass('dropdown')) {
                    $this.parent().addClass('open');
                    $('header').addClass('second-open');
                }
            }
        });

        var $lastScrollTop = 0;

        if ($(window).width() >= 1025) {
            $('body').on('mousewheel', function (e) {
                headerScroll(e);
            });

            $(document).on('scroll', function (e) {
                if (!$('body').hasClass('parallax-page')) {
                    transformHeaderNavigation(e);
                }
            });
        }

        window.headerCollapse = function(e) {
            headerScroll(e);
        };

        scrollHandler.functionCall('header');

        function headerScroll(e) {
            var $menuWrapper = $(e.target).closest('.main-submenu');

            if ($menuWrapper.height() + $('header').height() >= $(window).height()) {
                return;
            }

            if ($('body').hasClass('no-scrolling')) {
                e.preventDefault();
                e.stopPropagation();
            } else {
                if ($(document).scrollTop() >= 1) {
                    headerEl.addClass('small-header');
                    var $st = $(window).scrollTop();

                    if ($st >= ($(window).height())*0.5) {
                        headerEl.removeClass('up');
                    } else {
                        headerEl.addClass('up');
                    }
                    //$lastScrollTop = $st;
                } else {
                    headerEl.removeClass('small-header up');
                }
            }
        }

        /**
         * Set special class not on top to body if user isn't on top of the page.
         * Class is used mainly in header.less to change switch between enlarged and small header in desktop view.
         *
         * @param e element
         */
        function transformHeaderNavigation(e) {
            var $body = $('body'),
                pageScrollPosition = $(document).scrollTop();

            if (pageScrollPosition >= 1) {
                $body.addClass('not-on-top');
            } else {
                $body.removeClass('not-on-top');
            }
        }

        var $keys = new Array(33,34,35,36,37,38,39,40);

        $(document).keydown(function(e) {
            var $key = e.which;
            if($.inArray($key,$keys) > -1 && $('body').hasClass('no-scrolling')) {
                e.preventDefault();
                return false;
            }
            return true;
        });

        (function($) {
            $.fn.hasScrollBar = function() {
                return this.get(0).scrollHeight > this.get(0).clientHeight;
            }
        })(jQuery);

        // Clear styling when turning or resizing screen
        $(window).resize(headerScroll);
        $(window).resize(centerHeight);

        if (headerEl.find('.cart-items-total').length !== 0) {
            $.getJSON(config.cartQtyUrl, function (data) {
                headerEl.find('.cart-items-total').html(data)
            });
        }
    };

    return headerFunction;

});

