define('Ponbike_SmartFit/js/smartfit',['jquery', 'uiComponent', 'ko', 'domReady!'],
    function ($, Component, ko) {
        'use strict';

        return Component.extend({
            defaults: {
                template: 'Ponbike_SmartFit/smartfit',
                key: 'oz',
                units: 'metric',
                loaderSrc: 'https://widgets.onlinesizing.bike/loader.js',
                async: true,
                buttonId: '#smartfit-oz-btn'
            },

            initialize: function () {
                this._super();

                const self = this;
                $(document).on('swatch-option:selected', function(e, selectedId, data, jsonConfig){
                    self.changeSmartFitData(selectedId, data, jsonConfig);
                });

                /**
                 * Log clicks on the SmartFit button
                 */
                $('body').on('click', self.buttonId, function(e) {
                    if (!self.logUrl) {
                        return;
                    }

                    $.ajax({
                        url: self.logUrl,
                        type: 'POST',
                        data: {'sku': $(this).attr('data-oz-code')},
                    });
                });
            },

            initSmartFit: function () {
                window['___OnlineSizing'] = this.key;
                window['___OnlineSizingConfig'] = this.getSmartFitConfig();

                $('body').append('<script type="text/javascript" id="' + this.key + '" src="' + this.loaderSrc + '" async="' + this.async + '"></script>');
            },

            getSmartFitConfig: function () {
                return {
                    settings: {
                        apiKey: this.smartFitConfig.apiKey,
                        language: this.smartFitConfig.language,
                        primary: '#006400',
                        cta: '#800000',
                        buttonTextColor: '#ffffff',
                        roundness: '0px'
                    },
                    events: {
                        sizingAvailable: {
                            callback: this.initSmartFitLink,
                        },
                        confirmSize: {
                            callback: this.selectSize,
                        },
                        confirmSizeDoesNotFit: {
                            callback: this.selectSize,
                        },
                        confirmSizeUnavailable: {
                            callback: this.selectSize,
                        },
                    }
                }
            },

            initSmartFitLink: function () {
                if ($('.swatch-attribute.frame_size')) {
                    const $frameSizeLink = $('.framesize-link');

                    if ($('.oz-trigger')) {
                        $('.oz-trigger').show();
                        $('.swatch-attribute.frame_size').append($('.oz-trigger'));

                        if ($frameSizeLink) {
                            $frameSizeLink.remove();
                        }
                    }
                }
            },

            selectSize: function (data) {
                const selectedSizeLabel = data.hasOwnProperty('displaySize01') ? data.displaySize01 : null;
                if (selectedSizeLabel === null) {
                    return;
                }

                const $frameSizeAttribute = $('.frame_size');
                const $labelToSelect = $frameSizeAttribute.find(`.swatch-option[data-option-label='${selectedSizeLabel}']`);

                if ($labelToSelect.length) {
                    $labelToSelect.click();
                }
            },

            changeSmartFitData: function (selectedId, data, jsonConfig) {
                if (!this.productIdEanMapping.hasOwnProperty(selectedId)) {
                    return;
                }

                const selectedEan = this.getSelectedEan(selectedId);
                const selectedImage = this.getSelectedImage(selectedId, jsonConfig);

                if (selectedEan !== null && selectedImage !== null) {
                    const smartFitBtn = document.getElementById('smartfit-oz-btn');
                    if (smartFitBtn === null || typeof smartFitBtn === 'undefined') {
                        return;
                    }

                    smartFitBtn.setAttribute(`data-${oz.namespace}-code`, selectedEan);
                    smartFitBtn.setAttribute(`data-${oz.namespace}-image`, selectedImage);

                    if (oz && typeof oz.updateWidgetConfigurations === 'function') {
                        oz.updateWidgetConfigurations([smartFitBtn]);
                        console.warn('Initiated SmartFit');
                    } else {
                        console.warn('Could not initiate SmartFit correctly');
                    }
                }
            },

            /**
             * @returns {null|*}
             */
            getSelectedEan: function (selectedId) {
                return !this.productIdEanMapping.hasOwnProperty(selectedId)
                    ? null
                    : this.productIdEanMapping[selectedId];
            },

            /**
             * @returns {null|*}
             */
            getSelectedImage: function (selectedId, jsonConfig) {
                if (!jsonConfig.hasOwnProperty('images')) {
                    return null;
                }

                if (!jsonConfig['images'].hasOwnProperty(selectedId)) {
                    return null;
                }

                let selectedImg = null;
                $(jsonConfig['images'][selectedId]).each(function(index) {
                    const selectedIndex = jsonConfig['images'][selectedId][index];

                    if (!selectedIndex.hasOwnProperty('isMain') || !selectedIndex.hasOwnProperty('img')) {
                        return;
                    }

                    if (selectedIndex['isMain'] === true) {
                        selectedImg = selectedIndex['img'];

                        return false;
                    }
                });

                return selectedImg;
            }
        });
    }
);

