define('js/zoom-helper',[
    'galleryZoom',
    'matchMedia'
], () => {
    'use strict';

    return {
        initZoom: function(classNames = null, config = null, callback = null) {
            zoom(classNames, config, callback);
        },

        loadHighRes: function(target) {
            const desktopFullSizeImage = target.dataset.fullsizeDesktop,
                mobileFullSizeImage = target.dataset.fullsizeMobile;

            if (!desktopFullSizeImage && !mobileFullSizeImage) {
                return;
            }

            if (mobileFullSizeImage) {
                mediaCheck({
                    media: '(min-width: 768px)',
                    entry: () => this.setImageSource(target, desktopFullSizeImage),
                    exit: () => {
                        if (target.src !== desktopFullSizeImage) {
                            this.setImageSource(target, mobileFullSizeImage)
                        }
                    }
                });
            } else {
                this.setImageSource(target, desktopFullSizeImage);
            }
        },

        setImageSource: function(targetImage, fullSizeImage) {
            if (targetImage.src) {
                this.replaceImageWithHighRes(targetImage, fullSizeImage);
            } else {
                targetImage.onload = () => this.replaceImageWithHighRes(targetImage, fullSizeImage);
            }
        },

        replaceImageWithHighRes: function(targetImage, replaceImage) {
            const img = new Image();

            img.src = replaceImage;
            img.onload = () => {
                if (img.src !== targetImage.src) {
                    targetImage.src = img.src;
                }
            }
        },
    }
});

