define('Focus_Catalog/js/price-box',[
    'jquery',
    'Magento_Catalog/js/price-utils',
    'underscore',
    'mage/template',
    'catalogAddToCart',
    'jquery-ui-modules/widget',
    'Magento_Catalog/js/price-box'
], function ($, utils, _, mageTemplate, catalogAddToCart) {
    'use strict';

    var undefined;

    $.widget("focus.priceBox", $.mage.priceBox, {
        /**
         * Render price unit block.
         */
        reloadPrice: function reDrawPrices() {
            var priceFormat = (this.options.priceConfig && this.options.priceConfig.priceFormat) || {},
                priceTemplate = mageTemplate(this.options.priceTemplate);

            _.each(this.cache.displayPrices, function (price, priceCode) {
                price.final = _.reduce(price.adjustments, function (memo, amount) {
                    return memo + amount;
                }, price.amount);

                var element = $('[data-price-type="' + priceCode + '"]', this.element);
                var format = $.extend({}, priceFormat, {
                    requiredPrecision: element.data('precision') === undefined
                        ? priceFormat.requiredPrecision
                        : element.data('precision')
                });

                price.formatted = utils.formatPrice(price.final, format);

                element.html(priceTemplate({data: price}));
            }, this);
        }
    });

    return $.focus.priceBox;

});

