define('Focus_ESSearch/js/essearch',[
    'Vue',
    'vue!FocusESSearchComponents/ESSearch.vue',
    'vue!ESSearchComponents/SearchInput.vue',
    'vue!FocusESSearchComponents/ResultsGroup.vue',
    'vue!FocusESSearchComponents/Result.vue',
    'vue!ESSearchComponents/LoadingIndicator.vue'
], function (Vue) {
    'use strict';

    var mageJsComponent = function (config) {
        const appContainer = document.querySelector(config.element);

        if (!appContainer) {
            return;
        }

        new Vue({
            el: config.element,

            data: {
                search: {
                    query: '',
                    results: false,
                    loading: false,
                    triggerLimit: config.triggerLimit
                },
                translations: config.translations,
                showLoadMore: config.showLoadMore,
                showCategoryVisual: config.showCategoryVisual,
                scrollTopOnSearch: config.scrollTopOnSearch,
            }
        });
    };

    return mageJsComponent
});
