
(function(template){
    define("vue!FocusESSearchComponents/ESSearch.vue", ['Vue'], function (Vue) {

        Vue.component('es-search', {
            template: template,

            props: {
                'search': {
                    type: Object,
                    default: () => {
                        return {};
                    }
                },
                'translations': {
                    type: Object,
                    default: () => {
                        return {};
                    }
                },
                'showLoadMore': {
                    type: Boolean,
                    default: true
                },
                'showCategoryVisual': {
                    type: Boolean,
                    default: false,
                    required: false
                },
                'showAll': {
                    type: Boolean,
                    default: false,
                    required: false
                },
                'scrollTopOnSearch': {
                    type: Boolean,
                    default: true,
                    required: false
                }
            },

            methods: {
                clearSearch(query = '') {
                    this.search.query = query;
                    this.search.results = [];
                    this.search.loading = false;
                },

                performSearch(query) {
                    this.search.query = query;
                    this.search.loading = true;
                    this.showAll = false;

                    if (this.scrollTopOnSearch && window.scrollY) {
                        window.scroll(0, 0);  // reset the scroll position to the top left of the document.
                    }

                    //BASE_URL is a Magento2 global var with the Magento base url, so we can get localized results
                    fetch(BASE_URL + `rest/V1/essearch?q=${query}`)
                    .then(res => res.json())
                    .then(json => {
                        if(this.search.query.length < this.search.triggerLimit) {
                            this.clearSearch();
                            return;
                        }

                        this.search.loading = false;
                        if (Object.keys(json).length <= 0) {
                            this.search.results = false;
                            return false;
                        }

                        if (window.dataLayer) {
                            window.dataLayer.push({
                                event: 'searchQuery',
                                customSearchInput: this.search.query
                            });
                        }

                        this.search.results = json;
                    })
                    .catch(err => console.log(err))
                },

                showSearchResultPage() {
                    setTimeout(() => {window.location = BASE_URL + `bikes?q=${this.search.query}#page=1`}, 0 );
                },

                showBikeFinder() {
                    setTimeout(() => {window.location = BASE_URL + `bikes#page=1`}, 0 );
                }
            },

            computed: {
                hasResults() {
                    return (this.search.loading === false && this.search.query.length >= this.search.triggerLimit && this.search.results !== false);
                },

                noResults() {
                    return (this.search.loading === false && this.search.query.length >= this.search.triggerLimit && this.search.results === false);
                },

                hasPagesResults() {
                    if(typeof this.search.results.cms_page_ == "undefined") {
                        return false;
                    }
                    return true;
                },

                moreResults() {
                  let showMore = false;
                  for(key in this.search.results) {
                    if (this.search.results[key].items.length > this.renderLimit[key]) {
                      showMore = true;
                    }
                  }
                  return showMore;
                },

                renderLimit() {
                    return {
                        'product': this.hasPagesResults ? 3 : 8,
                        'category': 6,
                        'cms_page_': 6
                    }
                },

                resultsClasses() {
                    let classList = `essearch__results--columns-${Object.keys(this.search.results).length}`;

                    if (this.hasResults) {
                        classList += ' essearch__results--show';
                    }

                    return classList;
                }
            }
        });
    });
})('' + 
' <div class="essearch">' + 
' <search-input class="essearch__input" v-model="search.query" v-on:search="performSearch" v-on:clear="clearSearch" :placeholder="translations.search" :trigger-limit="search.triggerLimit"></search-input>' + 
'' + 
' <loading-indicator :loading="search.loading"></loading-indicator>' + 
'' + 
' <div v-if="noResults" :class="[\'essearch__notifications\', { \'essearch__notifications--show\' : noResults }]">' + 
' <h4 class="essearch__notifications-title">{{ translations.no_results }} "{{ search.query }}"</h4>' + 
' </div>' + 
'' + 
' <div v-if="hasResults" :class="[\'essearch__results\', resultsClasses]" :style="`--colNum: ${Object.keys(search.results).length}`">' + 
' <search-results-group v-for="type in Object.keys(search.results)" :key="type" :type="type" :data="search.results[type]" :render-limit="renderLimit" :bikefinder="translations.show_bikefinder" />' + 
' <div class="essearch__loadall" v-if="!showAll && moreResults">' + 
' <button class="essearch__loadall-button btn-focus" v-on:click="showSearchResultPage">{{ translations.all_results }}</button>' + 
' </div>' + 
' </div>' + 
' </div>' + 
'' + '');
