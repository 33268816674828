/**
 * @package Ponbike_Compare
 */
define('Ponbike_Compare/js/addItem',[
    'jquery'
], function ($) {
    'use strict';

    $.widget('mage.addItem', {

        options: {
            selectbox: false
        },

        elements: {
            compareBar: '.compare-bar',
            compareInner: '.compare-bar__inner',
            deleteItem: '.action.delete',
            replaceContainer: '.compare-replace',
        },

        /**
         * Initialize widget
         */
        _create: function () {
            var self = this;
            if (self.options.selectbox) {
                this._bindSelectBox();
            } else {
                $(this.element).on(
                    'click', self.elements.deleteItem, function () {
                        self._toggleCompareItem(
                            $(this).data('id'),
                            $(this).data('store'),
                            true
                        );
                    }
                );
            }

            $('.compare input').prop('disabled', false);

            window.addToCompare = function(productId, store){
                self._toggleCompareItem(productId, store);
            }

        },

        _bindSelectBox: function () {
            var self = this;
            $(self.element).on({
                'click': function () {
                    self._toggleCompareItem(
                        self.element.data('id'),
                        self.element.data('store')
                    );
                }
            });
        },

        _toggleCompareItem: function (productId, store, remove) {
            var self = this,
                checked = false;

            if ($(self.element).is(':checked')) {
                checked = true
            }

            $('.compare input[data-id="'+productId+'"]').each(
                function() {
                    $(this).prop('checked', checked);
                }
            );

            if (remove) {
                $('span[data-id="'+productId+'"]')
                    .removeClass('active');
            }
            $(self.element).prop('disabled', true);
            $(self.element)
                .closest('li.product-item')
                .addClass('compare-active');
            $(self.elements.compareInner).addClass('has-items');

            window.isOpen = $(self.elements.compareInner).length
                && $('.compare-bar__inner').hasClass('open');

            var updateObject = { bannerHTML: '', noItems: true };

            $(self.elements.compareBar)
                .addClass('loading');

            $.ajax({
                url: '/' + store + '/catalog/product_compare/ajax',
                data: {
                    'product': productId
                },
                success: function (returnValue) {
                    self._updateCompareBar(returnValue);
                    if (remove) {
                        var input = $('input[data-id="' + productId + '"]');
                        if (input.length !== false) {
                            $(input)
                                .each(function() {
                                    $(this).prop('checked', false);
                                });
                        }
                    }
                    $(self.element)
                        .closest('li.product-item')
                        .removeClass('compare-active');
                    $('body').trigger('contentUpdated');
                },
                complete: function (returnValue) {
                    Object.assign(updateObject, returnValue);
                }
            });
        },

        _updateCompareBar: function (cb) {
            var self = this,
                bannerHTML = cb.bannerHtml;

            var container = cb.hasOwnProperty('replace')
                ? self.elements.replaceContainer
                : self.elements.compareBar;

            $(container).html(bannerHTML);

            self._reload();
            self._finalize(cb, container);
        },

        _finalize: function(cb, container) {
            var self = this;
            if(cb.hasOwnProperty('replace')) {
                $(self.elements.compareBar)
                    .addClass('replace');
                $(container)
                    .addClass('active');
                $('body, html')
                    .addClass('overlay');
            }
            else {
                if (window.isOpen) {
                    $(self.elements.compareInner).addClass('open');
                }

                const visible = cb.noItems === false;
                self._setCompareBarVisibility(visible);
            }
        },

        _setCompareBarVisibility: function (visible) {
            var self = this;
            sessionStorage.setItem('show_compare_bar', visible);
            if( visible===true) {
                $(self.elements.compareBar)
                    .addClass('has-items')
                    .addClass('is-open');
                $('body').addClass('compare_bar_is-open');
            }else{
                $(self.elements.compareBar)
                    .removeClass('has-items')
                    .removeClass('active')
                    .removeClass('is-open');
                $(self.elements.compareInner)
                    .removeClass('open');

                $('body').removeClass('compare_bar_is-open');
                $(self.elements.compareBar)
            }
        },

        _reload: function () {
            $(this.element).prop('disabled', false);
            $(this.elements.compareBar).removeClass('loading');
        }

    });

    return $.mage.addItem;
});

