define(
    'Ponbike_LimitedEditionStock/js/limited-edition-stock',[
        'uiComponent',
        'jquery',
        'mage/translate'
    ],
    function (
        Component,
        $,
        $trans
    ) {
        "use strict";

        var self;

        return Component.extend({

            initialize: function (config) {
                this._super();
                self = this;
                self.request_url = config.request_url;
                self.threshold = parseInt(config.threshold);
                self.defaultStockLabel = $trans('Select your size to check availability');
                self.inStockLabel = config.inStockLabel;
                self.limitedStockLabel = config.limitedStockLabel;
                self.outOfStockLabel = config.outOfStockLabel;
                self.configureLabel = config.configureLabel;
                self.stockData = config.stockData;
                self.isLimitedEdition = config.isLimitedEdition;
                self.hasInitialStock = config.hasInitialStock;
                self.initEventHandlers();
                self.checkHasInitialStock();
            },

            checkHasInitialStock: function () {
                let stockStr = self.defaultStockLabel,
                    allOptionsSelected = self.allOptionsSelected();

                if(allOptionsSelected && !self.hasInitialStock) {
                    stockStr = self.outOfStockLabel;
                }

                $('#product-limited_edition-stock').html(stockStr);
                $('#product-addtocart-button').prop('disabled', true);
            },

            initEventHandlers: function () {
                $(document).on('product_id_selected', function (event, productId) {
                    self.selectProduct(productId);
                });
            },

            doRequest: function (id) {
                $.ajax({
                    url: self.request_url + id
                }).done(function (json) {
                    $('#product-addtocart-button').prop('disabled', true);
                    if (json.length) {
                        let obj = $.parseJSON(json);
                        let qty = parseInt(obj.qty);
                        self.changeStockStatus(qty);
                    } else {
                        self.changeStockStatus(false);
                    };
                });
            },

            /**
             * @return boolean
             */
            allOptionsSelected: function () {
                let allOptionsSelected = true;

                $('.swatch-attribute:visible').each(function (){
                    if (typeof $(this).attr('data-option-selected') === 'undefined') {
                        allOptionsSelected = false;
                    }
                });

                return allOptionsSelected;
            },

            selectProduct: function (productId) {
                self.changeStockStatus(self.stockData[productId]);
                $('#product-limited_edition-stock').attr('simple-id', productId);
            },

            changeStockStatus: function (qty) {
                var className,
                    stockText,
                    currentText,
                    isInStock,
                    allOptionsSelected = self.allOptionsSelected();

                if(!allOptionsSelected) {
                    stockText = self.defaultStockLabel;
                    className = "stockStatus--outOfStock";
                    isInStock = false;
                } else {
                    qty = parseInt(qty);
                    if(qty === false) {
                        $('#product-limited_edition-stock').html($trans('No stock information available'));
                        isInStock = false;
                    }

                    if (qty < 1) {
                        stockText = self.outOfStockLabel
                        className = "stockStatus--outOfStock";
                        isInStock = false;
                    } else {
                        stockText  = (qty > self.threshold) ? self.inStockLabel : self.limitedStockLabel;
                        if(self.isLimitedEdition) {
                            className  = (qty > self.threshold) ? "stockStatus--inStock" : "stockStatus--threshold";
                        } else {
                            className  = "stockStatus--inStock";
                        }
                        isInStock = true;
                        $('#product-addtocart-button').prop('disabled', false);
                    }
                }

                stockText = stockText.replace(/%1/g, qty);
                currentText = $('#product-limited_edition-stock').html();
                // Only change when different
                if (currentText !== stockText) {
                    $('#product-limited_edition-stock').html(stockText);
                    $('#product-limited_edition-stock').addClass(className);
                    var classList = $('#product-limited_edition-stock').attr('class');
                    if (classList) {
                        $.each(classList.split(/\s+/), function(index, item) {
                            if(item !== className) {
                                $('#product-limited_edition-stock').removeClass(item);
                            }
                        });
                    }
                }

                if (!isInStock) {
                    $('#product-addtocart-button').prop('disabled', true);
                }

                $(document).trigger('stock_changed', isInStock);
            },
        });
    }
);

