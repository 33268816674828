define('Focus_Catalog/js/hover',[
    'jquery'
], function ($) {
    'use strict';

    return function(config, el) {
        var $element = el,
            $sizes =  $('.geometry tr td, .geometry tr th', $element);

        $sizes.on('click', function() {
            var $thisClass = $(this).data('size');

            $sizes.removeClass('hover');

            $('.'+$thisClass).each(function(){
                $(this).addClass('hover');
            });
        });
    }
});
