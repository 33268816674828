/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
    'Focus_CookieManager/js/components/cookie-manager',[
        'jquery',
        'baseCookieManager',
        'underscore',
        'storageModel',
        'mage/template',
        'Magento_Ui/js/modal/modal',
        'mage/translate'
    ],
    function ($, Component, _, StorageModel, mageTemplate, modal, $t) {
        'use strict';

        return Component.extend({
            defaults: {
                settingsPopup: '.cookie-manager-popup',
                cookieManagerPopupClass: 'cookie-manager-popup',
                cookies: [],
                levels: [
                    {
                        name: 'functional',
                        allowed: true,
                        disabled: true
                    },
                    {
                        name: 'analytics',
                        allowed: true,
                        disabled: false
                    },
                    {
                        name: 'social',
                        allowed: false,
                        disabled: false
                    },
                    {
                        name: 'advertising',
                        allowed: false,
                        disabled: false
                    }
                ]
            },
            // Added modal object
            modal: null,

            initialize: function () {
                this._super();

                this.modal = modal({
                    show: false,
                    modalClass: this.cookieManagerPopupClass,
                    buttons: [
                        {
                            text: $.mage.__('Save preferences'),
                            click: function() {
                                this.saveSettings();
                            }.bind(this)
                        }
                    ]
                }, $(this.popupTemplate));

                // Set checkboxes to true on first visit in the background
                if (!StorageModel.get('user_allowed_save_cookie')) {
                    for (var i = 0; i < this.levels.length; i++) {
                        this.levels[i].allowed = true;
                    }
                }

                // Override base cookie manager to force all values to true on normal accept.
                $(this.allowCookiesButton).on('click', function () {
                    window.dataLayer.push({event: 'acceptCookies'});

                    for (var i = 0; i < this.levels.length; i++) {
                        this.levels[i].allowed = true;
                    }
                    this.saveSettings();
                }.bind(this));

                window.CM = this;
            },

            /**
             * Override default to prevent expires: -1 crashing the CookieHelper from lib/web/mage/cookies.js
             */
            filterCookies: function () {
                for (var i = 0; i < this.cookies.length; i++) {
                    for (var y = 0; y < this.levels.length; y++) {
                        if (this.cookies[i].level === this.levels[y].level && this.levels[y].allowed === false) {
                            $.mage.cookies.clear(this.cookies[i].name);
                        }
                    }
                }
            },

            setLevelBySwitch: function(name, element) {
                this.toggleSwitch(element);
                this.setLevel(name, $(element).attr("data-switch") === "on");
            },

            /**
             * Toggle a switch in the template
             *
             * @param switchElement
             */
            toggleSwitch: function (switchElement) {
                if ($(switchElement).attr("data-switch-disabled") === "disabled") {
                    return;
                }

                if ($(switchElement).attr("data-switch") === "on") {
                    $(switchElement).attr("data-switch", "off");
                } else {
                    $(switchElement).attr("data-switch", "on");
                }
            },

            /**
             * Opens modal to change level
             */
            changeLevel: function () {
                this.modal.openModal();
                window.dataLayer.push({event: 'changeCookieLevels'});
            },

            saveSettings: function(){
                this._super();
                window.dataLayer.push({
                    event: 'saveCookieLevels',
                    levels: this.levels
                });
            },

            /**
             * Hide the modal
             */
            hideCookieBlock: function () {
                this.modal.closeModal();
            },

            /**
             * Dummy file so strings are translatable. Magento searches for strings using the monsterous regex \$t\((?s)[^'"]*?(["'])(.+?)\1(?s).*?\)
             * in all JS files, and adds those to js-translations
             *
             * Since our cookie titles are in cookies.xml, and those are not picked up...
             * We just copy paste them here. Ugly, but efficient
             */
            dummyFunctionForDummies: function () {
                $t('Functional cookies');
                $t('Basic cookies necessary for the site to work normally');
                $t('Analytics cookies');
                $t('Cookies that track how users behave on our website');
                $t('Social cookies');
                $t('These cookies allow social media network to track your internet behavior');
                $t('Advertising cookies');
                $t('Cookies that will allow advertising platforms to track your behavior');
            }
        });
    }
);

