define(
    'Focus_AjaxPop/js/navigation-enhancement',[
        'jquery',
        'jquery/ui-modules/widgets/slider',
        'underscore',
        'mage/translate',
        'Pon_AJAXCatalog/js/navigation',
        'mage/template',
        'mage/apply/main',
        'mage/validation',
        'Magento_Swatches/js/swatch-renderer',
        'bootstrap'
    ],
    function (
        $,
        ui,
        _,
        $t,
        ajaxNavigation,
        mageTemplate,
        mage,
    ) {
        "use strict";

        var self,
            $document = $(document),
            $window = $(window),
            ajaxBusy;

        var filterClicks = 0;
        var oldReferrerHash = '';
        var oldReferrerHashBackup = '';
        var newReferrerHash = '';
        var filterTrigger = false;
        var redirectTriggered = false;
        var resetTrigger = false;

        return ajaxNavigation.extend({
            defaults: {
                allProductsExceptServiceTile: '.product-items li:not(.service-tile)',
                bannerSelector: '.banner-card',
                bannerTmpSelector: '#pon-ajaxcatalog-banner-default',
                filterContainers: '.js-filters.filter-options',
                hiddenOnDesktopClasses: 'hidden-md hidden-lg',
                allFiltersShown: false,
                navigationItemSelector: '.filter-options__content .filter-options__item, .filter-tiles__content .filter-tiles__option',
                loadMoreSelector: '.ajax-load-more',
                loadMoreTmpSelector: '#pon-ajaxcatalog-load-more-',
                lastClickedProductId: 'lastClickedProductId',
                showMoreFiltersSelector: '.show-more-filters-container',
                showMoreFiltersButtonTemplate: '#pon-ajaxcatalog-filter-show-more-filters-',
                zeroResultsTemplateSelector: '#pon-ajaxcatalog-zero-results-',
                noResultsSelector: '.no-results',
                toolbar: '.toolbar',
                toolbarAmountSelector: '.toolbar-amount',
                sliderSelectors: '.js-filters .option-slider',
                filterElementSelector: '#pon-toolbar',
                hiddenClass: 'hidden',
                navigationArea: '.js-filters',
                toolbarSelector: '.product-count',
                navigationButtonTmplSelector: '#pon-ajaxcatalog-navigation-button-',
                navigationItemClearSelector: '.bike-filter__form-clear',
                navigationItemClearBottomContainer: '.bike-filter__form-clear--bottom',
                navigationItemEmptySelector: '.filter-options__clear-current',
                navigationItemCloseButton: '.bike-filter__apply',
                priceSliderSelector: '#price-slider',
                batterySliderSelector: '#pim_itembatterywatthours-slider',
                moreBtnSelector: '.filter-options__more-button',
                slideFilterSelector: '.filter-options.option-slider',
                swatchOptionSelector: '.swatch-option',
                toggleFilterBtnSelector: '.toggle-filter',
                productItemClass: '',
                defaultPageTitle: document.title,
                defaultCategoryName: 'Bikes',
                productCardSelector: '.productCard-v2',
                productNameSelector: '.productCard-v2__name',
                productImageSelector: '.productCard-v2__image',
                categoryHeaderSelector: '.s--productsOverviewHeader',
                defaultCategoryImage: '/media/catalog/category/bgImage_1_.jpg'
            },
            productCategoryMapping: [],
            colorSwatchMapping: [],
            toolbarElement: $('.toolbar'),
            scroll: {
                offsetFromTop: 140
            },

            initProperties: function () {
                window.scrollTo(0, 0);
                this._super();
                self = this;
                self.$navigationButton = $(self.navigationItemCloseButton);
                self.preselectSortingOption();
            },

            rebuild: function (data) {
                this._super();
                self = this;
                ajaxBusy = 0;

                self.initClearFilter(data.navigation);
                self.initLoadMore(data);
                self.initNavigationButton(data.toolbar);

                if (data.banners && data.banners.length) {
                    self.initBanners(data.banners);
                    self.positionBanners();
                }

                self.scrollToLastClickedProduct();
                self.removeLastClickedProduct();
            },

            initLazyLoader: function () {
                return true; // Prevent loading on scroll
            },

            initLoadMore: function (data) {
                self.$loadMore = $(self.loadMoreSelector);

                if (self.$loadMore.length) {
                    var template = self.getTemplate(self.loadMoreTmpSelector, {data: data});

                    self.setInnerHtml(self.$loadMore[0], template);
                }
            },

            /**
             * @param data
             */
            initNavigationButton: function (data) {
                self = this;

                if (self.$navigationButton.length) {
                    self.setInnerHtml(self.$navigationButton[0], self.getNavigationButtonTemplate({data: data}));
                }
            },

            initEventHandlers: function () {
                self = this;

                $document.on('ajaxlist:initialized', function () {
                    self.initCollapsibleFilters();
                    self.initTooltips();
                    self.initFixedToolbar();
                });

                $document.on('ajaxlist:initList:after', function (event, data) {
                    self.afterInitializeList(data);
                    self.preselectSwatches();
                    self.orderList();
                    self.positionBanners();
                });

                $document.on('ajaxlist:initProduct:before', function (event, product) {
                    const productData = product.product;

                    self.initColorMapping(productData.color_mapping);
                });

                $document.on('ajaxlist:reset', function () {
                    self.resetToBaseLocation();
                    filterTrigger = true;
                    resetTrigger = true;
                });

                /**
                 * Range sliders
                 */
                $document.on('slide', self.sliderSelectors, function (event, ui) {
                    if (ui.values) {
                        $.each(ui.values, function (key, value) {
                            if ($(`#${event.currentTarget.id}-selector-${key}`).length > 0) {
                                $(`#${event.currentTarget.id}-selector-${key}`).val(value);
                            }
                        });
                    }
                });

                $document.on('slidechange', self.slideFilterSelector, function (event, ui) {
                    if (_.isEmpty(event.currentTarget.id)) {
                        return;
                    }

                    const elementId = event.currentTarget.id;
                    const values = ui.values;
                    let action = $('#'.elementId).closest('[data-attribute]').data('attribute');
                    let label;

                    if (0 in values === false || 1 in values === false) {
                        return;
                    }

                    action = (_.isUndefined(action)) ? elementId : action;
                    label = values[0] + ',' + values[1];

                    filterTrigger = true;
                });

                $(window).on('hashchange', function () {
                    if (self.detectNotBackButton()) {
                        if (!redirectTriggered) {
                            self.doRequest();
                        }
                    }
                });

                self.initClickHandlers();

                this._super();
            },

            toggleFilterItem: function (attr, value) {
                let filter = {},
                    params = self.getUrlParams();

                value = value.toString();

                if (_.has(params, attr)) {
                    if (_.contains(params[attr], value)) {
                        filter[attr] = _.filter(params[attr], function (paramValue) {
                            return paramValue !== value;
                        });
                        $.bbq.pushState(filter);
                        self.resetToBaseLocation(false);

                        return;
                    } else {
                        filter[attr] = params[attr].concat([value]);
                    }
                } else {
                    filter[attr] = [value];
                }

                $.bbq.pushState(filter);
            },

            getSerializedUrl: function () {
                let str = [],
                    obj = $.deparam.fragment();

                _.each(obj, function (value, index) {
                    if (_.isEmpty(value) || _.isEmpty(index)) {
                        return;
                    }

                    if (index !== 'page' && index !== 'price') {
                        index = index + "[]";
                    }

                    if (_.isArray(value)) {
                        _.each(value, function (valueItem) {
                            str.push(encodeURIComponent(index) + "=" + encodeURIComponent(valueItem));
                        });

                        return;
                    }

                    str.push(encodeURIComponent(index) + "=" + encodeURIComponent(value));
                });

                return str.join("&");
            },

            /**
             * Detect if back button is triggered, then return to the page before the POP
             *
             * @returns {boolean}
             */
            detectNotBackButton: function () {
                newReferrerHash = self.stripHashCode(window.location.hash);

                if (newReferrerHash === '') {
                    newReferrerHash = self.stripHashCode('#page=1');
                }

                if (newReferrerHash === oldReferrerHashBackup) {
                    if (!filterTrigger) {
                        history.go(filterClicks);
                        redirectTriggered = true;
                        return false;
                    }

                    if (resetTrigger) {
                        oldReferrerHash = oldReferrerHashBackup;
                    }
                }

                oldReferrerHashBackup = oldReferrerHash;
                oldReferrerHash = newReferrerHash;
                filterTrigger = false;
                redirectTriggered = false;
                resetTrigger = false;
                --filterClicks;

                return true;
            },

            dynamicSort: function (property) {
                let sortOrder = 1;

                if (property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }

                return function (a, b) {
                    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

                    return result * sortOrder;
                }
            },

            getLayerNavigationHtml: function (layers) {
                if (!_.isUndefined(layers)) {
                    let count = 1;

                    if (self.filterLimit !== false && !self.allFiltersShown) {
                        layers.map((layer) => {
                            if (layer.items) {
                                layer.items.sort(this.dynamicSort('value'));
                            }

                            if (!_.isUndefined(layer.attribute) && !_.isUndefined(layer.items)) {
                                count++;
                                layer.isActive = layer.items.some(function (filter) {
                                    return filter['active'] === true
                                });
                                layer.isShowMoreFilter = count > self.filterLimit && !layer.isActive;

                                return layer;
                            }
                        });
                    }
                }

                return this._super(layers);
            },

            initClickHandlers: function () {
                /**
                 * Sorting
                 */
                $('select#sorter').change(function () {
                    self.setSortOrder(this.value);
                });

                /**
                 * More btn
                 */
                $document.on('click', '#btn-pop-load-more', function () {
                    filterTrigger = true;

                    self.lazyLoadNextPage();

                    const lastProductId = $(`.product-items > li:not(${self.bannerSelector})`).last().find(`${self.productCardSelector}`).attr('id');
                    self.scrollToLastClickedProduct(lastProductId, 'auto');
                });

                /**
                 * Show more filters button
                 */
                $document.on('click', '#show-more-filters', function () {
                    $(self.filterContainers).children().removeClass(self.hiddenOnDesktopClasses);
                    self.allFiltersShown = true;
                    $document.trigger('ajaxlist:filters:shown');
                    $(this).remove();
                });

                /**
                 * Click on filter item
                 */
                $document.on('click change', self.navigationItemSelector, function () {
                    const $element = $(this);
                    const action = $element.closest('[data-attribute]').data('attribute');
                    const label = $element.closest('[data-value]').data('value');

                    filterTrigger = true;

                    if (_.isUndefined(action) || _.isUndefined(label)) {
                        return;
                    }
                });

                /**
                 * Empty
                 */
                $document.on('click', self.navigationItemEmptySelector, function (e) {
                    const $closestDataEl = $(e.target).closest('[data-attribute]');
                    const data = {
                        attribute: $closestDataEl.data('attribute')
                    };
                    filterTrigger = true;

                    const $checkedItems = $closestDataEl.find('.item:checked');

                    if ($checkedItems.length === 1) {
                        let $firstItem = $($checkedItems[0]);
                        let itemId = $firstItem.attr('id');
                        $('label.item[for="' + itemId + '"]').click();

                        return false;
                    }

                    $document.trigger('ajaxlist:clear', data);
                });

                /**
                 * Clear btn all filters
                 */
                $document.on('click', self.navigationItemClearSelector, function () {
                    filterTrigger = true;
                });

                /**
                 * Clear btn specific filter
                 */
                $document.on('click', self.navigationItemEmptySelector, function () {
                    const $element = $(this);
                    const label = $element.closest('[data-attribute]').data('attribute');

                    filterTrigger = true;
                });

                /**
                 * Mobile filter open/close
                 */
                $document.on('click', self.toggleFilterBtnSelector, function (ev) {
                    ev.preventDefault();
                    $('html, body').stop().animate({scrollTop: 0}, 200);
                    $('.s--productCatalog, .s--filterSidebar, .s--productsContainer, .toggle-filter').toggleClass('sidebar--open');
                });

                /**
                 * Swatch
                 */
                $document.on('click', self.swatchOptionSelector, function (e) {
                    if (!_.isUndefined(e.isTrigger)) {
                        return;
                    }

                    const $item = $(this);
                    const $parent = $item.closest('.product-item-details');
                    const label = $parent.find('.product-item-name a').text();
                    const type = ($item.hasClass('color')) ? 'color' : 'model';
                });

                $([self.productNameSelector, self.productImageSelector]).each(function () {
                    $document.on('click', self.setLastClickedProductEventHandler);
                });
            },

            /**
             * @param {object} data
             */
            afterInitializeList: function (data) {
                self = this;
                self.initSwatches(data.products);

                if (mage) {
                    mage.apply();
                }
            },

            /**
             * Set toolbar fixed on mobile
             */
            initFixedToolbar: function () {
                const navbar = $('.navbar-fixed-top');
                const openClass = 'open';
                const toolbarClone = self.toolbarElement.clone(true).addClass('fixed container');

                window.toolbarTop = self.toolbarElement.offset().top;

                if (!window.stickyToolbar) {
                    navbar.append(toolbarClone);
                    window.stickyToolbar = $('.toolbar.fixed');

                    $window.scroll(function () {
                        if ($window.scrollTop() >= window.toolbarTop) {
                            window.stickyToolbar.addClass(openClass);
                        } else {
                            window.stickyToolbar.removeClass(openClass);
                        }
                    });
                } else {
                    window.stickyToolbar.replaceWith(toolbarClone);
                    window.stickyToolbar = $('.toolbar.fixed');
                }
            },

            /**
             * Returns navigation button template
             * @param data
             * @returns {*}
             */
            getNavigationButtonTemplate: function (data) {
                return self.getTemplate(self.navigationButtonTmplSelector, data);
            },

            /**
             * Initialize the swatch data received from elastic search
             *
             * @param products
             */
            initSwatches: function (products) {
                _.each(products, function (product) {
                    if (_.has(product, 'swatches_js')) {
                        const $swatch = $('.swatch-opt-' + product['swatches_js']['productId']);

                        if (!$swatch.is(":data('pon-SwatchRenderer')")) {
                            $swatch.SwatchRenderer(product['swatches_js']);
                        }
                    }
                });
            },

            initTooltips: function () {
                const $tooltipIcons = $('.filter-options__tooltip');
                const tadaTooltip = $tooltipIcons.tooltip({
                    html: true,
                    viewport: $(self.navigationArea),
                    container: $(self.navigationArea),
                    placement: 'auto bottom'
                });
            },

            initFilters: function () {
                this._super();

                const self = this;

                $(self.batterySliderSelector).each(function (idx, element) {
                    $(element).slider(JSON.parse($(element).attr('data-config'))).on('slidechange', function (event, ui) {
                        self.setPage(1);

                        $.bbq.pushState({pim_itembatterywatthours: ui.values.join('-')});

                        self.doRequest(false);
                    });
                });
            },

            /**
             * Toggle long filter lists
             *
             * @param element
             */
            toggleAllFilters: function (element) {
                if (!_.isUndefined(element.isTrigger)) {
                    return;
                }

                const target = $(element.currentTarget);
                const parent = target.closest('.filter-options');
                const moreBtn = target.find('.more-button-text');
                const action = parent.closest('[data-attribute]').data('attribute');
                const label = 'show all';

                parent.toggleClass('item-hide');

                if (parent.hasClass('item-hide')) {
                    label = 'hide';
                    moreBtn.html('Hide');
                } else {
                    moreBtn.html('Show');
                }
            },

            setSortOrder: function (value) {
                $.bbq.pushState({product_list_order: value});
                filterTrigger = true;
                self.doRequest(false, {fromPage: 0});
            },

            initCollapsibleFilters: function () {
                const $filters = $('.js-filters'),
                    $trigger = $('.js-filters-collapse'),
                    filterHeight = 46;

                if ($filters.length && $filters[0].scrollHeight > filterHeight) {
                    $trigger.show();
                }
            },

            initClearFilter: function (filters) {
                if (_.isUndefined(filters)) {
                    return;
                }

                let hidden = true;
                const $clearSelector = $(self.navigationItemClearSelector);
                const $closeSelector = $(self.navigationItemCloseButton);

                _.find(filters, function (filter) {
                    if (filter.items && _.some(filter.items, (item) => item['active'] === true)) {
                        hidden = false;
                    }

                    if (filter.template === 'slider' || filter.template === 'slider-battery') {
                        let min = filter.config.min,
                            max = filter.config.max,
                            values = filter.config.values;

                        if (min !== parseFloat(values[0]) || max !== parseFloat(values[1])) {
                            hidden = false;
                        }
                    }
                });

                if (hidden === false) {
                    $clearSelector.parent().addClass('show--clear-button');
                    $clearSelector.removeClass(self.hiddenClass);
                    $closeSelector.removeClass(self.hiddenClass);
                } else {
                    $clearSelector.parent().removeClass('show--clear-button');
                    $clearSelector.addClass(self.hiddenClass);
                    $closeSelector.addClass(self.hiddenClass);
                }
            },

            initLayer: function (layer) {
                this.dispatchEvent('ajaxlist:initLayer:before', {layer: layer});
                this.layers = layer;

                let filterHtml = '';

                _.each(layer, function (attribute) {
                    filterHtml += this.getFilterTemplate({
                        data: _.extend(
                            attribute,
                            {
                                template: attribute.template ? attribute.template : attribute.attribute,
                                layer: layer
                            }
                        )
                    });
                }.bind(this));

                filterHtml += this.getFilterTemplate({data: {template: 'clear'}});

                if (this.filterList.length) {
                    this.setInnerHtml(this.filterList[0], filterHtml);
                }

                $document.on('click', this.navigationItemClearSelector, function () {
                    filterTrigger = true;
                    $document.trigger('ajaxlist:reset');
                });

                this.dispatchEvent('ajaxlist:initLayer:after', {filters: this.filterList});
                this.initFilters();
            },

            initLayeredNavigation: function (layer) {
                self.dispatchEvent('ajaxlist:initLayer:before', {layer: layer});

                if (self.$filterList.length) {
                    self.setInnerHtml(self.$filterList[0], self.getLayerNavigationHtml(layer));
                }

                self.dispatchEvent('ajaxlist:initLayer:after', {filters: self.$filterList});

                self.initFilters();
            },

            preselectSortingOption: function () {
                $("select#sorter option").each(function (index, elm) {
                    if ($(elm).val() === self.getUrlParams()['product_list_order']) {
                        $(elm).attr('selected', 'selected');
                    }
                });
            },

            /**
             * The color swatch mapping is generated by the indexer and added in elastic search.
             * The text filter is mapped with the tile color swatch.
             *
             */
            preselectSwatches: function () {
                const colorSwatchMapping = this.colorSwatchMapping;

                $('.swatch').each(function () {
                    let $swatch = $(this);

                    if ($swatch.is(":data('pon-SwatchRenderer')")) {
                        let uiInstance = $swatch.data('pon-SwatchRenderer');
                        uiInstance.updateSelectedItemGenderFrame();
                        uiInstance.updateSelectedItemColor(colorSwatchMapping);
                    }
                });
            },

            initBanners: function (banners) {
                for (let banner of banners) {
                    banner.is_enabled = Boolean(Number(banner.is_enabled));
                    banner.banner_full_width = Boolean(Number(banner.banner_full_width));
                }

                const bannerTemplate = mageTemplate(self.bannerTmpSelector);
                const bannerTemplateHtml = bannerTemplate({banners: banners});

                $('.product-items').append(bannerTemplateHtml);
            },

            /**
             * Refresh url hash params excl. unnecessary params
             */
            updateUrl: function () {
                const urlParams = _.omit(self.getUrlParams(), ['lazy', 'ajax']);
                const url = (new URL(document.location)).pathname + '#' + $.param(urlParams);

                window.history.replaceState(
                    null,
                    null,
                    url
                );

                self.navigateState = 0;
            },

            /**
             * Get the latest part of the URI e.g. elektrische-fietsen
             * and strip out all query parameters.
             *
             * @returns {string}
             */
            getCurrentUriPathName: function () {
                let anchor = document.createElement('a');
                anchor.href = window.location.href;

                return anchor.pathname.replace(/^\//, '');
            },

            /**
             * @returns {*}
             */
            getBannerTemplate: function (data) {
                return self.getTemplate(self.bannerTmpSelector, data);
            },

            /**
             * Reset the page title to the default value
             *
             * @returns void
             */
            resetPageTitle: function () {
                document.title = self.defaultPageTitle;
            },

            /**
             * Reset the category image to the default one
             *
             * @returns void
             */
            resetCategoryHeader: function (data) {
                data.name = self.defaultCategoryName;
                data.image = self.defaultCategoryImage;
            },

            initColorMapping: function (swatchColorMapping) {
                const self = this;

                $.each(swatchColorMapping, function (color, optionIds) {
                    if (self.colorSwatchMapping[color]) {
                        self.colorSwatchMapping[color] = _.union(self.colorSwatchMapping[color], optionIds);
                    } else {
                        self.colorSwatchMapping[color] = optionIds;
                    }
                });
            },

            /**
             * Adds CSS order to products
             */
            orderList: function () {
                $(self.allProductsExceptServiceTile).each(function (index, product) {
                    $(product).css('order', (index + 1) * 10);
                });
            },

            positionBanners: function () {
                $(self.bannerSelector).each(function (index, banner) {
                    $(banner).get(0).style.setProperty("--responsiveGridPosition", $(banner).data('position') - 1);
                });
            },

            /**
             * Get current currency
             *
             * @returns {string}
             */
            getCurrency: function () {
                return !_.isUndefined(window.dlCurrencyCode) ? window.dlCurrencyCode : 'EUR';
            },

            /**
             * Strip hash code
             *
             * @param str
             */
            stripHashCode: function (str) {
                return str.replace(/[_\s]/g, '').replace(/[^a-z0-9-\s]/gi, '').toLowerCase();
            },

            resetToBaseLocation: function (clear) {
                if (!self.ajaxProxyUrl) {
                    return;
                }

                clear = _.isUndefined(clear)
                    ? true
                    : clear;

                const request = (clear === false) ? "#" + self.getSerializedUrl() : "#page=1";

                window.history.pushState(null, null, self.baseLocation + request);
                self.ajaxProxyUrl = null;
            },

            setPage: function (page) {
                self.page = parseInt(page);

                $.bbq.pushState({page: self.page});
            },

            lazyLoadNextPage: function () {
                if (!self.isEnd) {
                    $window.off('scroll.ajaxlist');
                    self.loadNextPage();
                }
            },

            loadNextPage: function () {
                self.insertDirection = 'append';

                const lastPage = self.getLastLoadedPage();
                self.page = lastPage + 1;
                self.fromPage = lastPage;
                self.loadPage(self.page, self.fromPage);
            },

            loadPage: function (page) {
                self.setPage(page);
                self.doRequest(true, {fromPage: self.fromPage});
            },

            doRequest: function (lazy, params) {
                if (ajaxBusy !== 1) {
                    ajaxBusy = 1;

                    const lazy = false;
                    let params = null;
                    // Original call with cache set to true
                    self.isLazyLoad = !_.isUndefined(lazy) && lazy === true;

                    if (window.$preloadCategoriesEnabled === true
                        && !self.isInitialized
                        && typeof window.$preloadCategories !== 'undefined'
                    ) {
                        window.$preloadCategories.then(function (preloadResponseData) {
                            self.rebuild(preloadResponseData);
                            self.afterRequest();
                        });

                        return;
                    }

                    params = params || {};

                    const data = _.extend(self.getUrlParams(), {
                        ajax: true,
                        page: self.page,
                        isLazy: self.isLazyLoad
                    }, params);

                    $('body').trigger('processStart');
                    $.ajax({
                        url: self.url,
                        method: 'GET',
                        data: data,
                        cache: true, //CHANGED
                        success: function (response) {
                            self.rebuild(response);
                        }
                    }).done(function () {
                        self.afterRequest();
                        jQuery('body').trigger('processStop');
                    });
                    // End original call
                }
            },

            setLastClickedProductEventHandler: function (e) {
                const $element = $(e.target),
                    productCard = $element.closest(self.productCardSelector);

                if (productCard.length) {
                    self.setLastClickedProduct(productCard[0].id);
                }
            },

            scrollToLastClickedProduct: function (productId = false) {
                if (!productId) {
                    productId = self.getLastClickedProductId();
                }

                if (productId) {
                    self.setLastClickedProduct(productId);
                    self.scrollToProduct(productId);
                }
            },

            setLastClickedProduct: function (productId) {
                localStorage.setItem(self.lastClickedProductId, productId);
            },

            getLastClickedProductId: function () {
                return localStorage.getItem(self.lastClickedProductId);
            },

            removeLastClickedProduct: function () {
                localStorage.removeItem(self.lastClickedProductId);
            },

            scrollToProduct: function (productId, attempts = 0) {
                const $product = document.getElementById(productId);
                if (!$product) {
                    return;
                }

                if (!$product.attributes.hasOwnProperty('v-cloak')) {
                    self.addIntersectionObserver($product);

                    $product.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'start'
                    });
                } else {
                    if (attempts < 30) {
                        attempts += 1;
                        setTimeout(() => self.scrollToProduct(productId, attempts), 100);
                    }
                }
            },

            addIntersectionObserver: function (el) {
                const ob = new IntersectionObserver((payload) => {
                    if (payload[0].intersectionRatio === 1) {
                        window.scrollBy({
                            left: 0,
                            top: -self.scroll.offsetFromTop,
                            behavior: "smooth",
                        });

                        ob.unobserve(el);
                    }
                }, {
                    threshold: 1
                });

                ob.observe(el);

                return ob;
            },
        });
    }
);

