define('Ponbike_LanguageSwitcher/js/language-switcher',['jquery', 'mage/cookies'], function($) {

    var languageSwitcher = function(config) {
        var toggleElement = config.toggle;
        $(toggleElement).on('change', function () {
            if ($(this).attr('checked')) {
                $("html, body").animate({scrollTop: $(document).height() + 500}, 500);
            }
        })

        $(config.cookieTrigger).on("click", function() {
            var storeCode = $(this).context.getAttribute("data-storecode");
            var cookieDomain = $(this).context.getAttribute("data-cookieDomain");
            $.cookie("preferred_language", storeCode, { path: '/', domain: cookieDomain });

        });
    }

    return languageSwitcher;
});

