define('Ponbike_ProductNotifications/js/product-notifications',[
    'jquery',
    'Magento_Swatches/js/swatch-renderer',
    'mage/validation'
], function ($, swatch, validation) {
    $.widget('ponbike.productNotifications', {

        options: {
            notificationUrl: 'product-notification/notification/add',
            notificationContainerSelector: '.product-stock-notification',
            notificationMessageSelector: '.product-stock-notification-message',
            showNotificationFormSelector: '.show-address-notification-form',
            notSelectedNotificationBtnClass: 'not-selected',
            addressNotificationFormSelector: '.address-notification-form',
            notificationResultSelector: '.product-stock-notification-result',
            buttonSelector: '.js-product-stock-notification-button',
            addToCartId: '#product_addtocart_form',
            confirmationInput: 'confirmation',
            showFormOnSwatchSelect: true,
            formViewEvent: '',
            formViewSelector: '',
            optionMap: [],
            fieldData: [],
        },

        _create: function () {
            this._hookEvents();
            return this._superApply(arguments);
        },

        _hookEvents: function () {
            var self = this;
            this._hookFormSubmitEvent();
            this._hookShowNotificationFormButtonClickEvent();

            $(document).on('stock_changed', function(e, isInStock){
                return self._showNotification(isInStock);
            });
        },

        _hookSwatchSelectEvent: function () {
            $.each(
                this.options.optionMap.options,
                function (attributeId, options) {
                    const attributeWrapperSelector = 'div[attribute-id="' + attributeId + '"]';

                    $(attributeWrapperSelector).on(
                        'click',
                        '.swatch-option',
                        this._handleOptionSelect.bind(this)
                    )
                }.bind(this)
            )
        },

        _hookAddressFieldDisplayEvent: function () {
            $('body').on(
                this.options.formViewEvent,
                this.options.formViewSelector,
                this._showForm.bind(this)
            )
        },

        _hookShowNotificationFormButtonClickEvent: function () {
            $('body').on(
                'click',
                this.options.showNotificationFormSelector,
                this._showNotificationFormAction.bind(this)
            )
        },

        _hookFormSubmitEvent: function () {
            $(this.options.addressNotificationFormSelector).submit(
                this._submitNotification.bind(this)
            )
        },

        _handleOptionSelect: function (event) {
            this._hideNotificationForm();
            this._hideNotificationButton();
            this._clearNotificationResultMessage();
        },

        _submitNotification: function (event) {
            event.preventDefault();

            if (!$(this.options.addressNotificationFormSelector).validation('isValid')) {
                return;
            }

            let emailValue = $(this.options.emailFieldSelector).val();
            this.options.fieldData.notification_address = emailValue;
            this.options.fieldData.product_id = $('#product-limited_edition-stock').attr('simple-id');
            this.options.fieldData.confirmation = $('input[name="'+this.options.confirmationInput+'"]').val();

            this._startLoader();
            $.ajax(this.options.notificationUrl, {
                    method: 'POST',
                    data: this.options.fieldData,
                    success: function (response) {
                        let resultData = $.parseJSON(JSON.stringify(response));
                        this._showMessage(resultData.message)
                    }.bind(this),
                    error: function (response) {
                        let resultData = $.parseJSON(JSON.stringify(response));
                        this._showMessage(resultData.message);
                    }.bind(this),
                    complete: function () {
                        this._endLoader();
                    }.bind(this)
                }
            )
        },

        _showMessage: function (message) {
            this._showNotificationResultMessage(message);
        },

        _clearNotificationResultMessage: function () {
            $(this.options.notificationResultSelector).text('').hide();
        },

        _showNotificationResultMessage: function (message) {
            this._hideNotificationMessage();
            this._hideNotificationForm();
            $(this.options.notificationResultSelector).text(message).show();
        },

        _startLoader: function () {
            $(this.options.buttonSelector).addClass('disabled');
            $('body').trigger('processStart');
        },

        _endLoader: function () {
            $(this.options.buttonSelector).removeClass('disabled');
            $('body').trigger('processStop');
        },

        _hideNotification: function () {
            this._hideNotificationButton();
            this._hideNotificationForm();
            this._hideNotificationMessage();
        },

        _showNotification: function (isInStock) {
            this._hideNotificationForm();
            this._clearNotificationResultMessage();
            this._showNotificationMessage();

            if (!isInStock) {
                this._showNotificationButton();
            }
        },

        _hideNotificationContainer: function () {
            $(this.options.notificationContainerSelector).hide();
        },

        _showNotificationContainer: function () {
            $(this.options.notificationContainerSelector).show();
        },

        _hideNotificationButton: function () {
            $(this.options.showNotificationFormSelector).hide();
        },

        _showNotificationButton: function () {
            $(this.options.showNotificationFormSelector).show()
                .removeClass(this.options.notSelectedNotificationBtnClass);
        },

        _showNotificationMessage: function () {
            $(this.options.notificationMessageSelector).show();
        },

        _hideNotificationMessage: function () {
            $(this.options.notificationMessageSelector).hide();
        },

        _hideNotificationForm: function () {
            $(this.options.showNotificationFormSelector).hide();
            $(this.options.addressNotificationFormSelector).hide();
        },

        _showNotificationForm: function () {
            $(this.options.addressNotificationFormSelector).show();
        },

        _showNotificationFormAction: function (e) {
            e.preventDefault();

            if (!$(this.options.addToCartId).validation('isValid')) {
                return;
            }

            this._hideNotificationMessage();
            this._showNotificationForm();
        },

        _setProductId: function (productId) {
            this.options.fieldData.product_id = productId;
        },

        _getOptionById: function (optionId) {
            let resolvedOption = null;

            $.each(this.options.optionMap.options, function (attribute, options) {
                $.each(options, function (index, option) {
                    if (option.option_id == optionId) {
                        resolvedOption = option;
                    }
                });
            });

            return resolvedOption;
        }
    });

    return $.ponbike.productNotifications;
});

