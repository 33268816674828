
(function(template){
    define("vue!FocusESSearchComponents/Result.vue", ['Vue'], function (Vue) {
        Vue.component('search-result', {
            template: template,

            props: ['type', 'data']
        });
    });
})('' + 
' <div :class="`essearch__resultsItem essearch__resultsItem--${type}`">' + 
' <a :href="data.url" :class="[{\'essearch__resultsItem-container--noImage\': data.image===undefined }, `essearch__resultsItem-container essearch__resultsItem-container--${type}`]">' + 
' <img v-if="data.image" :class="`essearch__resultsItem-image-container essearch__resultsItem-image-container--${type}`" :src="data.image" :alt="data.title" />' + 
'' + 
' <div :class="`essearch__resultsItem-meta essearch__resultsItem-meta--${type}`">' + 
' <span v-if="data.title" :class="`essearch__resultsItem-title essearch__resultsItem-title--${type}`">{{ data.title }}</span>' + 
' <div v-if="data.price" :class="`essearch__resultsItem-price essearch__resultsItem-price--${type}`">{{ data.price }}</div>' + 
' </div>' + 
' </a>' + 
' </div>' + 
'' + '');
