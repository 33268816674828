define('Ponbike_WebDam360/js/360-modal',[
    'jquery',
    'reel',
    'zoomHelper',
    'underscore'
], function ($, reel, zoomHelper, _) {
    const SELECTORS_360 = {
        trigger: '.spinmodal__icon',
        reel: '#webdam360__',
        options: '.webdam360__options',
        option: '.webdam360__option__',
        zoom: 'webdam360__zoom',
        zoomIcon: '.webdam360__zoom-icon',
        modal_open: 'modal-open',
        spinmodal: 'spinmodal',
        spinmodalTriggers: '[data-spinmodaltrigger]',
    }

    const $body = document.querySelector('body'),
        modalTriggers = document.querySelectorAll(SELECTORS_360.spinmodalTriggers),
        modal = document.getElementById(SELECTORS_360.spinmodal);

    [...modalTriggers].forEach((trigger) => trigger.addEventListener('click', () => {
        const modalImage = document.querySelector('div[id^=webdam360__]');

        modal.classList.toggle('visible');
        $body.classList.toggle(SELECTORS_360.modal_open);
        $(modalImage.querySelector('img')).trigger('click');

        if (!$body.classList.contains(SELECTORS_360.modal_open)) {
            modalImage.dataset.scale = 1;
            modalImage.dataset.translateY = 1;
            modalImage.dataset.translateX = 1;
            modalImage.style.transform = null;
            modalImage.parentNode.classList.remove('zoom-active');

            $(SELECTORS_360.zoomIcon).show();
        }
    }));

    const debounce = (callback, wait) => {
        let timeoutId = null;

        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => callback.apply(null, args), wait);
        };
    }

    let previousReel = null;

    const updateReel = (images, zoomImages) => {
        const productId = modal.dataset.productid || Object.keys(images)[0];
        const imagesSimple = Object.values(_.pick(images, productId))[0];
        const zoomImagesSimple = zoomImages ? Object.values(_.pick(zoomImages, productId))[0] : false;

        if (!_.isUndefined(imagesSimple)) {
            if (previousReel) {
                previousReel.unreel();
            }

            const newReel = $(SELECTORS_360.reel + productId);

            $(SELECTORS_360.trigger).show();
            $(SELECTORS_360.options).hide();
            $(SELECTORS_360.option + productId).show();
            newReel
                .bind('frameChange', debounce((e, f, frame) => {
                    if (zoomImagesSimple) {
                        e.target.dataset.fullsizeDesktop = zoomImagesSimple[frame - 1];
                        e.target.dataset.fullsizeMobile = zoomImagesSimple[frame - 1];
                        zoomHelper.loadHighRes(e.target);
                    }
                }, 350))
                .reel({
                    shy: true,
                    cursor: 'move',
                    steppable: false,
                    images: imagesSimple,
                    hint: zoomImagesSimple ? 'Double click to zoom' : '',
                });

            previousReel = newReel;

            if (zoomImagesSimple) {
                $(SELECTORS_360.zoomIcon).show();

                zoomHelper.initZoom({ 'zoom': SELECTORS_360.zoom }, { scaleDefault: 4 }, (container, zoomed) => {
                    $(SELECTORS_360.zoomIcon).toggle(!zoomed);
                    newReel.data().options.draggable = !zoomed;
                });
            }
        } else {
            $(SELECTORS_360.trigger).hide();
        }
    }

    return (config) => {
        const zoomImages = (config.hasOwnProperty('zoomImagesString') && Object.keys(config.zoomImagesString).length)
            ? config.zoomImagesString
            : null;

        updateReel(config.imagesString, zoomImages);
        $(modal).on('datachange', () => updateReel(config.imagesString, zoomImages));
    }
});

