define('productGallery',[
    'jquery',
    'slick',
    'zoomHelper',
    'matchMedia'
], function ($, slick, zoomHelper) {
    'use strict';

    $.widget('mage.pdpGallery', {
        options: {
            zoomClass: 'zoom',
            zoomedInClass: 'zoom-active',
            zoomIcon: '.zoom__icon',
            hiddenClass: 'hidden',
            carouselSelector: '.product-gallery__items--main',
            modalCarouselSelector: '.product-gallery__items--modal',
            modalSelectorId: 'product-gallery-modal',
            modalTriggersAttribute: 'galleryModalTrigger',
            openModalBodyClass: 'modal-open',
            activeModalSlideSelector: '.product-gallery__items--modal .slick-active img'
        },

        _create: function () {
            this.initElements();
            this.initZoom();
            this.initSlickSliders();
            this.initModalTriggers();
        },

        initElements: function () {
            this.$gallery = this.element.find(this.options.carouselSelector);
            this.$modalGallery = this.element.find(this.options.modalCarouselSelector);
            this.$modal = document.getElementById(this.options.modalSelectorId);
            this.$zoomIcon = this.element.find(this.options.zoomIcon) ? this.element.find(this.options.zoomIcon)[0] : false;
        },

        initZoom: function () {
            const self = this;

            zoomHelper.initZoom({}, { scaleDefault: 4 }, (container, zoomed) => {
                const activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

                self.toggleZoomIcon(activeModalSlideImage, zoomed);
                self.toggleSwiping();
            })
        },

        initModalTriggers: function () {
            const self = this;

            document.addEventListener('click', (e) => {
                if (e.target.dataset.hasOwnProperty(self.options.modalTriggersAttribute)) {
                    self.toggleModal();
                }
            });

            document.addEventListener('keyup', (e) => {
                if (self.$modal.classList.contains('visible') && e.key === "Escape") {
                    self.toggleModal();
                }
            });
        },

        toggleModal: function () {
            const self = this;

            self.$modal.classList.toggle('visible');
            document.querySelector('body').classList.toggle(self.options.openModalBodyClass);

            if (!this.modalIsOpen()) {
                $(self.$gallery).slick('slickGoTo', 0, true);
            } else {
                zoomHelper.loadHighRes(document.querySelector(self.options.activeModalSlideSelector));
            }
        },

        modalIsOpen: function () {
            const self = this;

            return document.querySelector('body').classList.contains(self.options.openModalBodyClass);
        },

        toggleSwiping: function (forceEnable = false) {
            const self = this,
                activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

            if (forceEnable) {
                return $(self.$modalGallery).slick('slickSetOption', 'swipe', true);
            }

            if (activeModalSlideImage) {
                $(self.$modalGallery).slick('slickSetOption', 'swipe', !activeModalSlideImage.parentNode.classList.contains(self.options.zoomedInClass));
            }
        },

        toggleZoomIcon: function (activeSlide, forceHide = false) {
            const self = this;

            if (!self.$zoomIcon) {
                return;
            }

            if (forceHide) {
                return self.$zoomIcon.classList.add(self.options.hiddenClass);
            }

            if (activeSlide.parentNode.classList.contains(self.options.zoomClass)) {
                self.$zoomIcon.classList.remove(self.options.hiddenClass);
            } else {
                self.$zoomIcon.classList.add(self.options.hiddenClass);
            }
        },

        resetZoom: function () {
            const self = this,
                activeModalSlide = document.querySelector(self.options.activeModalSlideSelector);

            activeModalSlide.dataset.scale = 1;
            activeModalSlide.dataset.translateY = 1;
            activeModalSlide.dataset.translateX = 1;
            activeModalSlide.style.transform = null;
        },

        initSlickSliders: function () {
            const self = this;

            $(self.$gallery).slick({
                swipe: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                slide: '.product-gallery__slide',
                asNavFor: self.options.modalCarouselSelector,
            });

            $(self.$modalGallery).on('afterChange', () => {
                const activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

                self.toggleZoomIcon(activeModalSlideImage);
                zoomHelper.loadHighRes(activeModalSlideImage)
            });
            $(self.$modalGallery).on('beforeChange', () => {
                self.resetZoom();
                self.toggleSwiping(true);
            });
            $(self.$modalGallery).slick({
                swipe: true,
                arrows: true,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                slide: '.product-gallery__slide--modal',
                asNavFor: self.options.carouselSelector,
                prevArrow: '<a href="#" class="slick-arrow-prev"></a>',
                nextArrow: '<a href="#" class="slick-arrow-next"></a>',
            });

            // Initial load filter slides
            const slickGallery = $('.product-gallery__items--main').slick('getSlick'),
                slickModalGallery = $('.product-gallery__items--modal').slick('getSlick'),
                simpleId = $(slickGallery.$slides.get(0)).data('simples');

            [slickGallery, slickModalGallery].map((slickGallery) => {
                slickGallery.slickUnfilter();

                return slickGallery.slickFilter(`[data-simples*="${simpleId}"]`);
            });
        },
    });

    return $.mage.pdpGallery;
});

